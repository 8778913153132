<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";


import Choices from "choices.js";

import AgentAutoComplete from '@/components/agent-auto-complete-input'

import {
    required,
} from "vuelidate/lib/validators";

import moment from 'moment'

import { getBrokerageApi } from "@/api/brokerage";
import { financial } from '@/api/misc'
import { getArApApi } from "@/api/cash";


export default {
    page: {
        title: "Create AP Western ",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },

    validations: {
        expense: {
            post_date: { required },
        },
    },


    data() {
        return {
            title: "Create Board Exepnse",
            items: [
                {
                    text: "AP List",
                    href: "/ap/list",
                },
                {
                    text: "Create Board Expense",
                    href: "/ap/list",
                },
            ],

            expense: {
                supplier_id: '',
                expense: 0,
                invoice: ''
            },

            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },
            dropzoneOptions: {
                url: "/AwsFileUpload/s3_upload_multi",
                thumbnailWidth: 150,
                maxFilesize: 50,
            },


            office_choice: {},

            office_list: [],

            invoice_choice : {},
            invoice_list : [],

            fee_choice : {},
            fee_list : [],

            gl_entry_list: [],
            charge_agent_list: [],
            selected_agent: {},
            charge_agent_amount: 0,
            charge_agent_gst   : 0,
            part3_mu_incoice   :'',
            query_month: '',


            fee_amount : '',
            fee_gst    : '',
            current_fee : '',
            current_agent : {},

            sub_office_name : '',
            sub_office_code : '',

            deduction_fee_list : [],
            total_amount: 0,
            invoice_id   : '',
            is_submitting: false,

            regbv_gl     : '21040',
          
            commission_total : 0,
            monthly_total    : 0,
            gst_total        : 0,
            native_tmp_id : 10000,

        }
    },

    components: {
        Layout,
        PageHeader,
        flatPickr,
       
        AgentAutoComplete,
        


    },

    methods: {

        f(v) {
            return financial(v, 2)
        },
        formSubmit() {

            this.$v.$touch();

            if (this.$v.$invalid == false) {
                this.is_submitting = true
                let data = {
                    amount          : Number(this.total_amount),
                    payee_type      : 'SUPPLIER',
                    description     : this.expense.description,
                    post_date       : this.expense.post_date,
                    accounting_gl   : this.gl_entry_list,
                    invoice_id      : this.invoice_id,
                    agent_supplier_records : []
                }
               

                getArApApi().create_recoverable_expense(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$alertify.message("Post Succeed ");
                        this.$router.push({ name: 'ap-general' })
                    } else {
                        this.$alertify.error("Failed to Update " + res.errCode);
                    }
                    this.is_submitting = false
                })
            }
        },


        onExpenseSelected(evt) {
            this.current_fee = evt.fee
        },

       
      

    

        confirmToSubmit(fn, msg = 'Are you sure to submit?') {
            this.$alertify.confirmWithTitle(
                '', msg,
                () => {
                    this.formSubmit()
                },
                () => {
                }
            );
        },


       
        onAddChargedPart1Agent() {
           
            let cache = this.charge_agent_list.find(e => e.agent_id == this.current_agent.id)
            
            if (cache) {

                this.$alertify.confirmWithTitle('', 'are you sure to edit agent montly amount?',
                    () => {
                        cache.monthly_total = Number(this.charge_agent_amount)
                        cache.gst           = Number(this.charge_agent_gst)
                        cache.gst_total     = Number(this.charge_agent_gst) +  cache.commi_gst
                        cache.total         = this.f(cache.monthly_total  + cache.gst_total + cache.commission_total, 2)
                        cache.new_monthly_amount = Number(this.charge_agent_amount)
                        cache.new_monthly_gst    = Number(this.charge_agent_gst)
                        this.update_je_total_amount()
                    },
                    () => {
                    
                    }
                );

            } else {
                this.charge_agent_list.push({
                    agent_id         : this.current_agent.id,
                    agent_name       : this.current_agent.first_name +' ' + this.current_agent.last_name,
                    commission_total : 0,
                    commi_gst        : 0,
                    monthly_total    : Number(this.charge_agent_amount),
                    gst              : Number(this.charge_agent_gst),

                    gst_total        : Number(this.charge_agent_gst),
                    total            : Number(this.charge_agent_amount) + Number(this.charge_agent_gst),
                })
                this.update_je_total_amount()
            }
            

            
        },


        onSelectedAgent(evt) {
            this.current_agent = evt.agent
        },

        query_recoverable_fee_list() {
            let data = {
                office_code : this.expense.office_code,
                query_month : this.query_month,
            }
            getArApApi().query_western_agent_fee_list(data).then(res => {
               

                if (res.errCode == 0) {
                  
                    this.charge_agent_list = []

                    res.data.map(agent => {
                        let obj = {
                            agent_id         : agent.agent_id,
                            agent_name       : agent.agent_name,
                            commission_total : this.f(agent.commission.reduce((a, c) => a + Number(c.amount), 0), 2),
                            commi_gst        : this.f(agent.commission.reduce((a, c) => a + Number(c.gst), 0), 2),

                            monthly_total    : this.f(agent.monthly.reduce((a, c) => a + Number(c.amount), 0), 2),
                            monthly_gst      : this.f(agent.monthly.reduce((a, c) => a + Number(c.gst), 0), 2),
                        }
                     
                        obj.gst_total        = obj.monthly_gst + obj.commi_gst
                        obj.total            = obj.commission_total + obj.monthly_total + obj.gst_total

                        this.charge_agent_list.push(obj)
                    })

                    this.update_je_total_amount()

                   
                   

                }
            })
        },

        onRemoveChargedAgent(data) {
            this.charge_agent_list.splice(data.index, 1)
            this.update_je_total_amount()
        },

      
        onOfficeChanged(evt) {
           
           let office_obj =  this.office_list.find(e => e.value == evt.detail.value)
           this.sub_office_name = office_obj.c.name
       },

       getAgentPart1FootValue(column) {
            if (column.column =='Commission') {
                return '$'+financial(this.commission_total, 2).toLocaleString()
            } else if (column.column =='Monthly') {
                return '$'+financial(this.monthly_total, 2).toLocaleString()
            } else if (column.column =='GST') {
                return '$'+financial(this.gst_total, 2).toLocaleString()
            } else if (column.column =='Total') {
                return '$'+financial(this.gst_total + this.commission_total + this.monthly_total, 2).toLocaleString()
            }
        },

        update_je_total_amount () {
            this.commission_total = this.f(this.charge_agent_list.reduce((a, c) => a + Number(c.commission_total), 0), 2)
            this.monthly_total    = this.f(this.charge_agent_list.reduce((a, c) => a + Number(c.monthly_total), 0), 2)
            this.gst_total        = this.f(this.charge_agent_list.reduce((a, c) => a + Number(c.gst_total), 0), 2)
            let gl_entry    = this.gl_entry_list.find(e => e.gl_number == this.regbv_gl)
            gl_entry.amount = 0
            gl_entry.amount  = this.commission_total + this.monthly_total  + this.gst_total
                   
        }


    },


    created() {
        moment()
       
        this.gl_entry_list.push({
            gl_number : '21000',
            gl_label  : 'AP Supplier',
            amount    : 0,
        })

        this.gl_entry_list.push({
            gl_number : '21040',
            gl_label  : 'Remax Western',
            amount    : 0,
        })

        getBrokerageApi().officeApi.list({ page: { page: 1, page_size: 50 } }).then((res) => {
            if (res.errCode == 0) {
                this.office_list = []
                res.data.map((s) => {
                    this.office_list.push({
                        label: s.branch_name,
                        value: s.office_code,
                        c: s
                    })
                })

                this.office_choice.clearChoices()
                this.office_choice.setChoices(this.office_list)

            }
        })



    },

    mounted() {
       
        this.office_choice = new Choices('#choices-office-input', {
            placeholderValue: "This is a placeholder set in the config",
            searchPlaceholderValue: "search by name",
        })


    },


    watch: {
        total_amount  (new_value) {
            let supplier_gl =  this.gl_entry_list.find(e => e.gl_number == '21000')
            if (supplier_gl) {
                supplier_gl.amount = -Number(new_value)
            }
        },

        fee_amount (new_value) {
            this.fee_gst = financial(Number(new_value) * 0.05, 2)
        },

        charge_agent_amount (new_value) {
            this.charge_agent_gst = financial(Number(new_value) * 0.05, 2)
        }
    },
}
</script>


<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="confirmToSubmit(formSubmit)">


                    <div class="row">

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="choices-office-input" class="form-label">Office</label>
                                <select class="form-control" v-model="expense.office_code" name="choices-office-input"
                                    id="choices-office-input">
                                </select>
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label>Total Amount</label>
                                <input id="validationCustom01" v-model="total_amount" type="text" class="form-control"
                                    placeholder="$" value=""  />
                            </div>
                        </div>

                        <div class="col-md-2">
                            <div class="mb-3">
                                <label for="validationCustom03">Post Date</label>
                                <flat-pickr v-model="expense.post_date" placeholder="Select a date" class="form-control"
                                    :class="{ 'is-invalid': $v.expense.post_date.$error,  }"></flat-pickr>
                                <div v-if="$v.expense.post_date.$error" class="invalid-feedback">
                                    <span v-if="!$v.expense.post_date.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        

                        <div class="col-md-6">
                            <div class="mb-3">
                                <label for="validationCustom01">Description</label>
                                <input id="validationCustom01" v-model="expense.description" type="text"
                                    class="form-control" placeholder="" value="" />
                            </div>
                        </div>

                    </div>
                    <!-- end row-->

                    <hr class="my-4" />
                   

                    <div class="row mt-5" v-if="is_submitting">
                        <b-alert show variant="danger">
                            <b-spinner variant="danger" label="Spinning" class="me-2"></b-spinner>
                            Submitting Charge Data, please waiting.....
                        </b-alert>
                    </div>

                    <hr class="my-4 mb-3" />
                    <div class="row mt-5"></div>


                    <div class="row mt-5">
                        <b-tabs class="mt-n5 mt-5" content-class="card card-body mt-3" nav-class="nav-tabs-custom"
                            nav-wrapper-class="nav-tabs-custom">
                            <b-tab title="Accounting">
                                <div class="table-responsive">
                                    <div class="table align-middle table-nowrap">
                                        <b-table :items="gl_entry_list" :fields="[{ key: 'GL' }, { key: 'Amount' }, { key: 'Action' }]"
                                            responsive="sm" :per-page="gl_entry_list.length" class="table-check ">

                                            <template #cell(GL)="data">
                                                {{ data.item.gl_number + '('+data.item.gl_label+')' }}
                                            </template>
                                            <template #cell(Amount)="data">
                                                ${{ data.item.amount.toLocaleString() }}
                                            </template>
                                            <template #cell(Action)>
                                               
                                            </template>

                                        </b-table>
                                    </div>
                                </div>
                            </b-tab>

                            <b-tab title="Agent" >
                                <div class="row">
                                  
                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="validationCustom03">Query Month</label>
                                            <flat-pickr v-model="query_month" placeholder="Select a month" :config="{dateFormat: 'Ym'}"
                                            @on-change="query_recoverable_fee_list" class="form-control"></flat-pickr>

                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="mb-3">
                                            <label for="choices-fee-input" class="form-label">Agent</label>
                                            <AgentAutoComplete  @onSelected="onSelectedAgent"  autocomplete="off"  />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="choices-fee-input" class="form-label">Amount</label>
                                            <input type="text" v-model="charge_agent_amount" class="form-control" placeholder="$" />
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="mb-3">
                                            <label for="choices-fee-input" class="form-label">Gst</label>
                                            <input type="text" v-model="charge_agent_gst" class="form-control" placeholder="$" />
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="mb-3 mt-4">
                                            <b-button variant="secondary" type="button" @click="onAddChargedPart1Agent">Add Agent</b-button>
                                        </div>
                                    </div>
                                </div>
                                <!-- end row -->

                                <div class="table-responsive">
                                    <b-table :items="charge_agent_list"
                                        :fields="['Name',  'Commission', 'Monthly', 'GST', 'Total', 'Action']"
                                        responsive="sm" :per-page="charge_agent_list.length" class="table-check datatables" foot-clone>
                                        <template #cell(Name)="data">
                                            {{ data.item.agent_name }}
                                        </template>

                                        <template #cell(Commission)="data">
                                            ${{ f(data.item.commission_total).toLocaleString() }}
                                        </template>
                                       
                                        <template #cell(Monthly)="data">
                                            ${{ f(data.item.monthly_total).toLocaleString() }}
                                        </template>
                                       
                                        <template #cell(GST)="data">
                                            ${{ f(data.item.gst_total).toLocaleString() }}
                                        </template>

                                        <template #cell(Total)="data">
                                            ${{ f(data.item.total).toLocaleString() }}
                                        </template>

                                        <template #cell(Action)="data">
                                            <b-button variant="outline-danger" size="sm"
                                                @click="onRemoveChargedAgent(data)">
                                                <i class="mdi mdi-close"></i>
                                            </b-button>
                                        </template>

                                        <template #foot()="data">
                                            <i>{{ getAgentPart1FootValue(data) }}</i>
                                        </template>

                                    </b-table>
                                </div>
                            </b-tab>
                            <!-- End Part 1 Tab-->


                        </b-tabs>

                    </div>
                    <!-- end row-->

                    <div class="row">
                        <div class="col-md-12">
                            <div class="d-flex flex-wrap gap-2 justify-content-center d-flex align-items-center">
                                <b-button variant="secondary" type="button" @click="$router.push({name : 'ap-general'})">Cancel</b-button>
                                <b-button variant="primary" type="submit" :disabled="is_submitting== true">Submit</b-button>
                            </div>
                        </div>
                       
                    </div>
                    <!-- end row-->


                </form>
            </div>
        </div>
        <!-- end card -->
    </Layout>
</template>

<style scoped>
  #invoice_dropzone {
    letter-spacing: 0.2px;
    height: 150px;
  }
</style>